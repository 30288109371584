import { PaginatedResponse } from '@eagle/api-types';
import { InputTypes, PropertiesDefinition, PropertyDefinition, TypeDefinitionTypes } from '@eagle/common';
import { Alert, Autocomplete, Button, PopperProps, Stack, TextField } from '@mui/material';
import { useDebounce } from '@react-hook/debounce';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { API_CALL_TEXT_LENGTH, LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { FetchAllCache, usePromise } from '../../hooks';
import { CommonEntity, CommonEntityWithProperties, Nullable } from '../../types';
import { filterDeletedCache, FILTER_OUT } from '../../util';
import { AppliedFilterType, EntityField, FilterFieldNew, SelectedFilterType } from '../entity-search';
import { FilterPathIdentifiers, FilterTypes } from '../filter';
import { FilterInputString } from '../filter/filter-input-string';
import { useBoolFlag } from '../flags';

interface Props {
  'data-testid'?: string;
  appliedFilters: (filterType: FilterTypes) => AppliedFilterType[];
  entityTypeCache: FetchAllCache;
  entityTypePath: FilterTypes;
  filterEntityType?: (entityTypes: CommonEntityWithProperties) => boolean;
  getEntityTypeId: (entity: CommonEntityWithProperties) => string;
  includeDeleted?: boolean;
  pathIdentifier?: FilterPathIdentifiers;
  propertyPathPrefix?: 'properties' | 'sharedProperties';
  submitFilter: (field: FilterFieldNew, value: SelectedFilterType) => void;
  transformProperties?: (input: { properties: PropertiesDefinition; sharedProperties?: PropertiesDefinition }) => { properties: PropertiesDefinition; sharedProperties?: PropertiesDefinition };
}

const EXCLUDED_ATTRIBUTES = ['date'];

export const FilterAttributesInput: FC<Props> = ({
  appliedFilters,
  entityTypeCache,
  entityTypePath,
  transformProperties,
  getEntityTypeId,
  includeDeleted,
  pathIdentifier,
  propertyPathPrefix,
  submitFilter,
  filterEntityType,
  ...props
}): JSX.Element => {
  const [selectedType, setSelectedType] = useState<Nullable<CommonEntityWithProperties>>(null);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [filterString, setFilterString] = useState<{ value: string; display?: string }>({ value: '' });
  const sharedPropertyFilterFlag = useBoolFlag('portals-global-filtering-component-enable-reference-shared-property-filtering');
  const { t } = useTranslation(['common']);
  const appliedTypeFilters = appliedFilters(entityTypePath) as EntityField[];

  const [entityTypes] = usePromise(async () => {
    if (!includeDeleted) {
      const entityTypes = await filterDeletedCache<CommonEntityWithProperties>(entityTypeCache);
      return filterEntityType ? entityTypes.filter(filterEntityType) : entityTypes;
    }
    const entityTypes = await entityTypeCache.all<CommonEntityWithProperties>();
    return filterEntityType ? entityTypes.filter(filterEntityType) : entityTypes;
  }, [entityTypeCache, includeDeleted, filterEntityType]);

  useEffect(() => {
    if (!appliedTypeFilters.length) {
      setSelectedType(null);
      return;
    }
    if (appliedTypeFilters.length !== 1 || !entityTypes) return;
    const type = entityTypes.find((entityType) => getEntityTypeId(entityType) === appliedTypeFilters[0].id);
    if (type) setSelectedType(type);
  }, [entityTypes, appliedTypeFilters, getEntityTypeId]);

  const handleTypeSelect = (selected: Nullable<CommonEntityWithProperties>): void => {
    setSelectedType(selected);
    handleTypeFilter(selected);
    setSelectedProperty('');
  };

  const handleTypeFilter = (selected: Nullable<CommonEntityWithProperties>): void => {
    if (!selected) return;
    const field: FilterFieldNew = {
      definition: {
        description: null,
        format: 'raw',
        input: InputTypes.CHECK,
        label: t('common:common.labels.type'),
        multiple: null,
        type: 'entity',
      },
      path: entityTypePath,
      pathIdentifier,
    };
    const value: SelectedFilterType = [{
      id: getEntityTypeId(selected),
      display: selected.display,
    }];
    submitFilter(field, value);
  };

  const getTypeProperties = ({ properties, sharedProperties }: CommonEntityWithProperties): string[] => {
    const transformedProperties = transformProperties ? transformProperties({ properties, sharedProperties }) : { properties, sharedProperties };
    let typeProperties = transformedProperties.properties.order.filter((property) => !EXCLUDED_ATTRIBUTES.includes(property));
    if (sharedPropertyFilterFlag && transformedProperties.sharedProperties) {
      typeProperties = [...typeProperties, ...transformedProperties.sharedProperties.order];
    }
    return typeProperties;
  };

  const getDefinitions = ({ properties, sharedProperties }: CommonEntityWithProperties): Record<string, PropertyDefinition> => {
    const definitions = { ...properties.definitions, ...sharedPropertyFilterFlag && sharedProperties ? sharedProperties.definitions : {} };
    return definitions;
  };

  const handleAddAttribute = ({ value, display }: { value: string; display?: string }): void => {
    if (!selectedProperty || !selectedType) return;
    const isSharedProps = sharedPropertyFilterFlag && selectedType.sharedProperties && selectedType.sharedProperties.order.includes(selectedProperty);
    const propertyPath = propertyPathPrefix ?? (isSharedProps ? 'sharedProperties' : 'properties');
    const field: FilterFieldNew = {
      definition: selectedType.sharedProperties && isSharedProps ? selectedType.sharedProperties.definitions[selectedProperty] : selectedType.properties.definitions[selectedProperty],
      path: `${propertyPath}.${selectedProperty}`,
      pathIdentifier,
    };
    submitFilter(field, display ? [{ display, id: value }] : value);
    setSelectedProperty('');
  };

  const renderTypeSelect = (): JSX.Element => {
    if (!entityTypes) return <></>;
    return <>
      <Autocomplete
        componentsProps={{
          popper: {
            'data-testid': 'type-select-popper',
          } as Partial<PopperProps>, // Incorrect type in @mui/material doesn't allow passing data attributes, so we need the assertion.
        }}
        disableClearable={selectedType !== null}
        getOptionLabel={({ display }) => display}
        isOptionEqualToValue={(option, value) => getEntityTypeId(option) === getEntityTypeId(value)}
        onChange={(_, value) => {
          if (value) {
            handleTypeSelect(value);
          }
        }}
        options={entityTypes}
        renderOption={(props, option) => {
          return <li data-testid={getEntityTypeId(option)} {...props}>{option.display}</li>;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:component.filter.labels.types')}
            placeholder={t('common:component.filter.hint.showing-all-types')}
            variant="outlined"
            {...props}
          />
        )}
        size="small"
        value={selectedType ?? null}
      />
      {!selectedType
        && <Alert severity="info" sx={{ fontSize: 'small', ml: '0px !important', width: '100% !important' }}>
          {t('common:component.filter.hint.attributes-enabled')}
        </Alert>
      }
    </>;
  };

  const renderPropertiesSelect = (): JSX.Element => {
    if (!selectedType) return <></>;
    const properties = getTypeProperties(selectedType);
    const definitions = getDefinitions(selectedType);
    const getOptionDisplay = (option: string): string => {
      return definitions[option]?.label || option;
    };
    return (
      <Autocomplete
        onChange={(_, value) => {
          setSelectedProperty(value ?? '');
          setFilterString({ value: '' });
        }}
        options={properties}
        getOptionLabel={getOptionDisplay}
        renderOption={(props, option) => (
          <li {...props}>{getOptionDisplay(option)}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common:component.filter.labels.attribute')}
            placeholder={t('common:component.filter.hint.select-an-attribute')}
            variant="outlined"
            data-testid="attribute-select-text-input"
          />
        )}
        size="small"
        value={selectedProperty}
      />
    );
  };

  const renderPropertiesFilterField = (): ReactNode => {
    const definition = selectedType?.sharedProperties?.definitions?.[selectedProperty] ?? selectedType?.properties?.definitions?.[selectedProperty];

    if (!definition) {
      return null;
    }

    switch (definition.type) {
      case TypeDefinitionTypes.REFERENCE:
        return (
          <ReferencePropertyFilter
            key={selectedProperty}
            sharedThingTypeId={selectedType?.sharedThingTypeId}
            property={selectedProperty}
            onChange={(value) => { setFilterString(value ? { value: value._id, display: value.display } : { value: '' }); }}
          />
        );

      default:
        return (
          <FilterInputString
            key={selectedProperty}
            acceptEmptyValue
            disabled={!selectedProperty}
            onChanged={(value) => setFilterString({ value })}
            useOnKeyUp
            data-testid="attribute-value"
          />
        );
    }
  };

  return <>
    {renderTypeSelect()}
    <Stack spacing={1} sx={{ display: selectedType ? 'auto' : 'none' }}>
      {renderPropertiesSelect()}
      {renderPropertiesFilterField()}
    </Stack>
    <Button
      disabled={!selectedProperty || !filterString.value.length}
      onClick={() => handleAddAttribute(filterString)}
      sx={{ alignSelf: 'end' }}
      data-testid="add-attribute-button"
    >
      {t('common:component.filter.action.add-attribute')}
    </Button>
  </>;
};

interface ReferencePropertyFilterProps {
  onChange: (value: Nullable<CommonEntity>) => void;
  property: string;
  sharedThingTypeId?: string;
}

const ReferencePropertyFilter: FC<ReferencePropertyFilterProps> = ({ onChange, property, sharedThingTypeId }) => {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const [searchValue, setSearchValue] = useDebounce('', LOOKUP_DEBOUNCE_TIME);

  const isSearchValueTooShort = searchValue.length > 0 && searchValue.length < API_CALL_TEXT_LENGTH;

  const [options, , status] = usePromise(async () => {
    if (isSearchValueTooShort || !sharedThingTypeId) {
      return null;
    }
    return axios.get<PaginatedResponse<CommonEntity>>(`/api/v2/shared-thing-type/${sharedThingTypeId}/reference-lookup/${property}`, {
      params: {
        dependantProperties: {},
        filter: FILTER_OUT.deleted,
        search: searchValue || undefined,
      },
    });
  }, [axios, isSearchValueTooShort, sharedThingTypeId, property, searchValue]);

  return (
    <Autocomplete
      size='small'
      filterOptions={(option) => option}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      options={options?.data.items ?? []}
      getOptionLabel={({ display }) => display}
      loading={status === 'pending' || isSearchValueTooShort}
      loadingText={isSearchValueTooShort ? t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH }) : t('common:common.labels.loading')}
      onChange={(_, value) => {
        onChange(value);
      }}
      onInputChange={(_, inputValue, reason) => {
        setSearchValue(reason === 'reset' ? '' : inputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="reference-select-input"
          variant="outlined"
        />
      )}
    />
  );
};
