import { RoleFunction, Time } from '@eagle/common';
import { Box, Button, Divider, ListItemButton, Popover, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDebounce } from '@react-hook/debounce';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useAuthenticated } from '../../auth';
import { API_CALL_TEXT_LENGTH, LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { useCustomRoutes } from '../../hooks';
import { scrollbar } from '../../style';
import { Nullable } from '../../types';
import { FILTER_OUT, switchToPortal, testid, useHasAuthorization } from '../../util';
import { useBoolFlag } from '../flags';
import { MiddleSpinner } from '../middle-spinner';

export const MAX_ACCOUNTS = 5;

const ADMIN_ROLE = [RoleFunction.ADMINISTRATOR] as const;
const ACCOUNT_ADMIN_ROLE = [RoleFunction.ACCOUNT_ADMINISTRATOR] as const;

interface AccountSwitcherProps {
  anchorEl: Nullable<HTMLElement>;
  onClose: VoidFunction;
  onSelect?: (value: string) => void;
}

export const AccountSwitcher: FC<AccountSwitcherProps> = ({ anchorEl, onClose, onSelect }) => {
  const { recentAccounts } = useAuth();

  const open = Boolean(anchorEl);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1400 }}
      onClick={(e) => { e.stopPropagation(); }}
    >
      <AccountSwitcherInner
        onClose={onClose}
        onSelect={(value) => {
          onClose();
          onSelect?.(value);
        }}
        recentAccounts={recentAccounts}
      />
    </Popover>);
};

interface AccountSwitcherInnerProps {
  onSelect: (value: string) => void;
  onClose: VoidFunction;
  recentAccounts: string[];
}

interface RequestParams {
  filter: Record<string, any>;
  limit?: number;
  search?: string;
}

const AccountSwitcherInner: FC<AccountSwitcherInnerProps> = ({ onClose, onSelect, recentAccounts }) => {
  const { t } = useTranslation(['common']);
  const { restClient, userInfo } = useAuthenticated();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useDebounce('', LOOKUP_DEBOUNCE_TIME);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [isShowingMore, setIsShowingMore] = useState(false);
  const searchRef = useRef<HTMLInputElement>();
  const isSearching = debouncedSearch.length > 0;
  const hasNoRecentAccounts = recentAccounts.length === 0 || (recentAccounts.length === 1 && recentAccounts[0] === userInfo.baseAccountId);
  const customRoutes = useCustomRoutes();
  const hasManageAccountsFeature = useBoolFlag('manage-accounts-feature');
  const { hasAuthorization } = useHasAuthorization();

  const hasMinimumSearchLength = debouncedSearch.length >= API_CALL_TEXT_LENGTH;

  const { data: accounts, status: accountStatus } = useQuery({
    queryKey: ['account-switcher-accounts', debouncedSearch, recentAccounts, userInfo.baseAccountId, isSearching, hasNoRecentAccounts, hasMinimumSearchLength],
    staleTime: Time.seconds(15),
    queryFn: async () => {
      const params: RequestParams = {
        filter: FILTER_OUT.deleted,
      };
      if (!isSearching) {
        if (hasNoRecentAccounts) {
          params.filter = { ...params.filter, _id: { $ne: userInfo.baseAccountId } };
          params.limit = MAX_ACCOUNTS;
        }
        else {
          params.filter = { ...params.filter, _id: { $in: recentAccounts, $ne: userInfo.baseAccountId } };
        }
      }
      else if (hasMinimumSearchLength) {
        params.search = debouncedSearch;
        params.filter = { ...params.filter, _id: { $ne: userInfo.baseAccountId } };
      }
      else {
        return null;
      }

      const accounts = await restClient.my.accounts.getAll({
        ...params,
      });

      if (!isSearching && !hasNoRecentAccounts) {
        accounts.sort((a, b) => recentAccounts.findIndex((id) => a._id === id) - recentAccounts.findIndex((id) => b._id === id));
      }
      return accounts;
    },
  });

  useEffect(() => {
    if (!isSmallScreen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isSmallScreen]);

  const displayRecentLabel = !isSearching && !hasNoRecentAccounts;
  const displaySearchHint = isSearching && !hasMinimumSearchLength;
  const displayResults = !isSearching || hasMinimumSearchLength;
  const isLoading = displayResults && accountStatus !== 'success';
  const displayShowMoreButton = displayResults && (accounts && accounts.length > MAX_ACCOUNTS);
  const displayAllAccountsLink = hasAuthorization(ADMIN_ROLE) && hasAuthorization(ACCOUNT_ADMIN_ROLE) && hasManageAccountsFeature;

  const visibleAccounts = isShowingMore ? accounts : accounts?.slice(0, MAX_ACCOUNTS);

  return (
    <Stack sx={{ maxHeight: isShowingMore ? 465 : 350, width: 250 }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="caption">{t('common:component.switch-account.switch-account.labels')}</Typography>
        <TextField
          data-testid="account-switcher-input"
          fullWidth
          inputRef={searchRef}
          inputProps={{
            'aria-label': t('common:component.lookup.hint.placeholder'),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
            setDebouncedSearch(e.target.value);
          }}
          placeholder={t('common:component.lookup.hint.placeholder')}
          size="small" value={search}
          variant="standard"
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', ...scrollbar }}>
        {displayRecentLabel && <Typography variant="caption" sx={{ px: 2 }}>{t('common:component.switch-account.recent.labels')}</Typography>}
        {displaySearchHint && <Typography sx={{ px: 2, pt: 0, pb: 1 }} variant="body2" data-testid="account-switcher-minimum-character">{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Typography>}

        {!isLoading ? (
          <div>
            {visibleAccounts?.length === 0 && <Typography sx={{ px: 2, pt: 0, pb: 1 }} variant="body2" data-testid="account-switcher-no-result">{t('common.hint.list.no-results')}</Typography>}
            {visibleAccounts?.map((account) => (
              <ListItemButton
                data-testid={testid`account-switcher-item-${account._id}`}
                key={account._id}
                onClick={() => {
                  onSelect(account._id);
                }}
                sx={{ px: 2 }}
              >
                {account.display}
              </ListItemButton>
            ))}
          </div>
        ) : (
          <MiddleSpinner sx={{ py: 2 }} size={20} />
        )}
      </Box>

      {displayShowMoreButton && (
        <Button data-testid="account-switcher-show-more-button" onClick={() => { setIsShowingMore((value) => !value); }}>
          {isShowingMore ? t('common:component.switch-account.show-less.labels') : t('common:component.switch-account.show-more.labels')}
        </Button>
      )}
      {displayAllAccountsLink && (
        <Box sx={{ mt: 'auto' }}>
          <Divider />
          <ListItemButton
            data-testid="account-show-all-accounts-button"
            component="a"
            href={switchToPortal(null, 'admin', search ? `${customRoutes.accounts}?searchText=${encodeURIComponent(search)}` : `${customRoutes.accounts}`)}
            onClick={onClose}
            sx={{ px: 2 }}
          >
            {t('common:component.switch-account.show-all-accounts.labels')}
          </ListItemButton>
        </Box>
      )}
    </Stack>
  );
};
